
  import styles from './Bags.module.css';
  import helpers from '../../helpers.js';
  import {useState, useContext, useEffect, useRef} from 'react';

  import { UserContext } from '../../UserContext.js';

  import Module from "../../components/Module.js";
  import TextInput from "../../components/TextInput.js";
  import Selector from "../../components/Selector";
  import Button from "../../components/Button.js";
  import DateSelector from '../../components/DateSelector.js';
  import iconCSV from '../../assets/images/iconCSV.png';

  import BagPopup from './BagPopup.js';


  function Bags(props) {
    const {user, setUser} = useContext(UserContext);
    const [searchFilter, setSearchFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("all");
    const [siteFilter, setSiteFilter] = useState("all");
    const [dateFilter, setDateFilter] = useState("production");
    // alert(new Date()).setDate((new Date()).getTime() + 30 * 24 * 3600 * 1000);
    const d = new Date();
    d.setDate((new Date()).getDate() - 14);
    const [fromFilter, setFromFilter] = useState(formatDateToYYYYMMDD(d));
    const [toFilter, setToFilter] = useState(formatDateToYYYYMMDD(new Date()));
    const [overlayBag, setOverlayBag] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'charcode', direction: 'ascending' });


    const [bags, setBags] = useState([{
      id: "CHA-000"
    }]);
    const [filteredBags, setFilteredBags] = useState(bags);
    const [farmers, setFarmers] = useState([]);



    const sortBags = (bagsToSort) => {
      if (!sortConfig.key){
        alert("keyyyyy: " + sortConfig.key)
        return bagsToSort;
      } 

      const sortedBags = [...bagsToSort].sort((a, b) => {
        let aValue, bValue;
        console.log("sortConfig.key")
        console.log(sortConfig.key)

        switch (sortConfig.key) {
          case 'farmer':
            aValue = a._order?._user?.last_name?.toLowerCase() || '';
            bValue = b._order?._user?.last_name?.toLowerCase() || '';
            break;
          case 'site':
            aValue = a._site?.name?.toLowerCase() || '';  
            bValue = b._site?.name?.toLowerCase() || '';
            break;
          case 'scheduled':
            console.log("SCHEDULED / a:" + a._order.delivery_date.time + " b: " + b._order.delivery_date.time)
            aValue = a._order ? new Date(a._order.delivery_date.time): "";
            bValue =  b._order ? new Date(b._order.delivery_date.time): "";//ew Date(b.bagging_date);
            console.log(aValue + " " + bValue)
            break;
          case 'date':
            aValue = new Date(a.bagging_date);
            bValue = new Date(b.bagging_date);
            break;
          case 'pickedUp':
            aValue = new Date(a.locations.pickup.time);
            bValue = new Date(b.locations.pickup.time);
            break;
          case 'production':
            aValue = new Date(a.bagging_date);
            bValue = new Date(b.bagging_date);
            break;
          case 'assigned':
            aValue = new Date(a.locations.bagging.time);
            bValue = new Date(b.locations.bagging.time);
            break;
          case 'application':
            aValue = a.locations.application ? new Date(a.locations.application.time) : null;
            bValue = b.locations.application ? new Date(b.locations.application.time) : null;
            break;
          case 'completed':
            aValue = a.locations.delivery ? new Date(a.locations.delivery.time) : null;
            bValue = b.locations.delivery ? new Date(b.locations.delivery.time) : null ;
            break;
          default:
            aValue = a[sortConfig.key] || '';
            bValue = b[sortConfig.key] || '';
            break;
        }

          // Compare values based on sort direction
          if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
          if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
          return 0;
        });

      return sortedBags;
    };
    


    const getFilteredDate = (bag) => {
      let bagDateType;
      console.log(dateFilter)
      switch(dateFilter){
        case "select": 
          bagDateType = ["select"];
          return true;
          break;
        case "completed": 
          bagDateType = ["locations", "delivery","time"]; //CHECK FIRST IF LOCATIONS.DELIVERY.TIME works, if not use delivery date? Discuss with Em and Shefali
          break;
        case "scheduled": 
        //  console.log(" SCHEDULED")
        //  console.log(bag )

          bagDateType = ["_order", "delivery_date"]; //CHECK FIRST IF LOCATIONS.DELIVERY.TIME works, if not use delivery date? Discuss with Em and Shefali
          break;
        case "production": 
          bagDateType = ["bagging_date"];
          break;
        case "assigned": 
          bagDateType = ["locations", "bagging", "time"];
          break;
        case "pickedUp": 
          bagDateType = ["locations", "pickup", "time"];
          break;
        case "application": 
          bagDateType = ["application_date"];
          break;
        default:
          console.log(dateFilter)
          break;
      }
      let date = JSON.parse(JSON.stringify(bag));
      // console.log(date)
      bagDateType.forEach((str) => {
        if(typeof date[str] !== "undefined"){
          date = date[str];
        } else {
          date = "-"
        }
      })
      return date;
    }

    useEffect(() => {
      // Function to apply all filters to the bags list
      const filterBags = () => {
        let updatedBags = [...bags];
        console.log(updatedBags)

        // Filter by search term
        if (searchFilter) {
          updatedBags = updatedBags.filter(bag => bag.charcode.includes(searchFilter) || bag.batch_id.includes(searchFilter) || bag.status.includes(searchFilter) || bag._site && bag._site.name.includes(searchFilter) || bag._order && bag._order._id.includes(searchFilter) || bag._delivery && bag._delivery.includes(searchFilter) || bag._id.includes(searchFilter) || bag._order && bag._order.products[0]._product.includes(searchFilter) || bag._order && bag._order._user.first_name.includes(searchFilter) || bag._order && bag._order._user.last_name.includes(searchFilter) || bag._order && bag._order._user.business_name.includes(searchFilter));
        }

        // Filter by status
        if (statusFilter !== "all") {
          updatedBags = updatedBags.filter(bag => bag.status === statusFilter);
        }

        // Filter by site
        if (siteFilter !== "all") {
          console.log(siteFilter)
          updatedBags = updatedBags.filter(bag => bag._site && bag._site.name === siteFilter);
        }


        // Filter by date range
        const fromDate = new Date(fromFilter);
        const toDate = new Date(toFilter);
        
        // console.log(updatedBags)
        console.log(fromDate + " - " + toDate )

        updatedBags = updatedBags.filter(bag => {
        
          // console.log("HEREEEEE")
          // console.log(bagDateType)
          // console.log(date)

      // Dynamically access the property
      // const value = bagDateType.split('.').reduce((obj, key) => obj && obj[key], bag);
      // console.log("Here" + value)
          const bagDate = getFilteredDate(bag);
          console.log(bagDate)
          console.log(bag.charcode);
          console.log("HERE")
          console.log(typeof bagDate)
          // console.log(typeof bag[bagDateType] !== undefined);
          // console.log(bagDateType);
          // console.log(bagDate);
          return ((typeof bagDate !== undefined) && 
          (new Date(bagDate) >= fromDate) && 
          (new Date(bagDate) <= toDate) && 
          (bag.status === "unassigned" || bag.status === "bagged" || bag.status === "assigned" || bag.status === "applied" || bag.status === "pickedUp" || bag.status === "delivered" )) ; //REVIEW
        });
        console.log("HELloo") 
        console.log(updatedBags)
        updatedBags = sortBags(updatedBags);
        setFilteredBags(updatedBags); //TEMPORARY SOLUION
        console.log(updatedBags.map((bag)=>  bag._order ? bag._order.delivery_date : null));
        console.log(updatedBags)

      };

      filterBags();
    }, [searchFilter, statusFilter, siteFilter, dateFilter, fromFilter, toFilter, bags, sortConfig]);


    const handleSort = (key) => {
      console.log(key)
      let direction = 'ascending';
      console.log(sortConfig.key) //CHARCODE INSTEAD OF scheduled
      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
      console.log("AA")
      console.log(sortConfig)
    };


    const fetchBags = async () => {
      const request = {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      };
      console.log(user.backEndURL);
      let response = await fetch(user.backEndURL + "/bags", request);
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        // let newFarmers = [];
        // jsonResponse.data.forEach((farmer) => {
        //   let farmerDate = new Date(farmer.delivery_date)
        //   let dateString = farmerDate.getDay() + "/" + farmerDate.getMonth() + "/" + farmerDate.getYear();
        //   let newFarmer = {
        //     id: farmer._id,
        //     status: farmer.status,
        //     deliveryDate: dateString,
        //     pincharBags: farmer.products[0].amount,
        //     pelletcharBags: farmer.products[1].amount,
        //     weight: 2.2,
        //     volume: 8
        //   }
        //   newFarmers.push(newFarmer);
        // })
        
        setBags(jsonResponse.data.bags);
        console.log(jsonResponse.data.bags);
        setFilteredBags(jsonResponse.data.bags);
        setFarmers(jsonResponse.data.farmers)
        console.log(jsonResponse.data.bags)
        console.log("SILENTLssY COMPLETES ITS MISSION, LIKE A NINJA");
      } else {
        console.log("Fetch Subscription Failed...")
      }
    }

    useEffect(() => {
      console.log("Fetching Bags")
    
      fetchBags();
    },[user]) 


    const openBagEditor = (bag) => {
      setOverlayBag(bag);
    }

    const closeBagEditor = (bag) => {
      setOverlayBag(null);
    }

    
    const resetFilters = () => {
      setSearchFilter("");
      setStatusFilter("all");
      setSiteFilter("all");
      setDateFilter("assigned");
      setFromFilter(formatDateToYYYYMMDD(new Date("01/10/2019")));
      setToFilter(formatDateToYYYYMMDD(new Date()));
    }

    const returnStatusName = (statusName) => {
      switch(statusName){
          case "pickedUp": 
              return "Picked Up";
          default: 
              return statusName.charAt(0).toUpperCase() + statusName.slice(1)
      }
    }

    const returnFilteredDate = () => {
      
    }

    const renderBags = () => {

      if(filteredBags.length === 0){ //No data placeholder

        return(
          <tr className={styles.bagsRow}>
            <td className={styles.placeholderContainer}><div className={styles.placeholderShape}>No data...</div></td>
            <td className={styles.placeholderContainer}><div className={styles.placeholderShape}/></td>
            <td className={styles.placeholderContainer}><div className={styles.placeholderShape}/></td>
            <td className={styles.placeholderContainer}><div className={styles.placeholderShape}/></td>
            <td className={styles.placeholderContainer}><div className={styles.placeholderShape}/></td>
          </tr>
        )
      }

      return(
        <>
          {
            filteredBags.map((bag, i) => {
              if(i>100){
                return(<></>)
              }
              if(filterAndDisplayBag(bag)){
                // console.log(bag.charcode)
                return(<></>)
              }
              if(!bag._order){
                // console.log("AAAAAAA")
                // console.log(bag)
              }
              console.log("bag")

              console.log(bag._order ? bag._order._user.first_name : "n/a")
              return(
                <>
                  <tr className={styles.bagsRow}>
                    <td className={helpers.clx(styles.bagsValue, styles.tableCharcode)}>
                      {bag.charcode}
                    </td>
                    <td className={styles.bagsValue}>
                      {bag.status ? returnStatusName(bag.status) : "n/a"}
                    </td>
                    <td className={helpers.clx(styles.bagsValue, bag._order ? "": styles.greyedOut)}>
                      {bag._order ? bag._order._user.first_name.substring(0,1) + ". " +  bag._order._user.last_name  : "n/a"}
                    </td>
                    <td className={helpers.clx(styles.bagsValue, bag.site ? "": styles.greyedOut)}>
                      {bag._site ? bag._site.name : "-"}
                    </td>
                    <td className={helpers.clx(styles.bagsValue, bag._order ? "": styles.greyedOut)}>
                      {/* {!bag.bagging_date ? "-" : bag.bagging_date.substring(0,10)} */}{getFilteredDate(bag).toString().substring(0,10)}
                    </td>
                    <td className={styles.editRowIcon} onClick={() => openBagEditor(bag)}>
                      pencil
                    </td>
                  </tr>
                </>
              )
            })
          }
        </>
      )
    }


    /*
      const [searchFilter, setSearchFilter] = useState("");
      const [statusFilter, setStatusFilter] = useState("All");
      const [siteFilter, setSiteFilter] = useState("All");
      const [fromFilter, setFromFilter] = useState(null);
      const [toFilter, setToFilter] = useState(null);
    */

    const filterAndDisplayBag = (bag) => {
      if(searchFilter === "" && statusFilter === "All" && siteFilter === "All" && dateFilter === "select" && fromFilter === null && toFilter === null){
        return true;
      }
      // alert("NOT")
      return false;
    }

    const exportToCSV = () => {
      // CSV Header (corresponding to your desired Excel format)
      const headers = [
        'Produced', 'ID', 'Bag Weight', 'Avg. MC', 'Delivered',
        'Del. Date', 'Del. ID', 'Order. ID'
      ];

      // Generate CSV content
      const csvContent = filteredBags.map(item => {
        return [
          formatDate(item.bagging_date),  // Produced (formatted date)
          item.charcode || 'n/a',         // ID
          item.weight || 'unrecorded',    // Bag Weight
          item.moisture_content || 'unrecorded',                   // Avg. MC (assuming unrecorded)
          (item.status === "delivered" || item.status === "applied") ? "TRUE" : "FALSE",                        // Del. Assigned (default as FALSE)
                            // Delivered (default as TRUE)
          formatDate(item.delivery_date),       // Del. Date (example static value)
          typeof item._order !== "undefined" && typeof item._order._delivery !== "undefined"  ? item._order._delivery.delivery_id : "",                             // Del. ID (leave empty)
          typeof item._order !== "undefined" ? item._order.order_id : ""                           // Empty column
        ].join(',');
      });

      // Combine the headers and the rows
      const csv = [headers.join(','), ...csvContent].join('\n');

      // Create a Blob and download the CSV file
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    // Helper function to format dates into dd/mm/yy
    const formatDate = (dateString) => {
      if (!dateString) return 'unrecorded';  // If date is undefined, return 'unrecorded'
      
      const date = new Date(dateString);     // Convert ISO string to JavaScript Date object
      const day = String(date.getDate()).padStart(2, '0');  // Extract day, pad with 0 if needed
      const month = String(date.getMonth() + 1).padStart(2, '0');  // Extract month, pad with 0 (getMonth is zero-based)
      const year = String(date.getFullYear());  // Extract last two digits of the year
      
      return `${day}/${month}/${year}`;  // Return formatted string
    };


    console.log(dateFilter)
    return (
      <div className={"contentGrid"}>
        {overlayBag !== null ? 
          <BagPopup closePopup={closeBagEditor} bag={overlayBag} farmers={farmers} bags={bags}/>
          :
          null
        }
        <Module name={"Biochar Bags"} spanColumn={24} spanRow={2} height={"100%"}>
            <div className={styles.filtersRow}>
              <div className={styles.exportToCSVContainer}>
                <img src={iconCSV} className={styles.iconCSV} onClick={exportToCSV}/>
                ({filteredBags.length})
              </div>
              <div className={helpers.clx(styles.filterContainer, styles.searchContainer)}>
                <TextInput value={searchFilter} onChange={setSearchFilter} name={"Search"} labelStyle={"top"} placeholder={"Enter Keyword"}/>
                <div className={helpers.clx(styles.fontAwesome, styles.searchIcon)}>
                  search
                </div>
              </div>
              <div className={helpers.clx(styles.filterContainer, styles.statusContainer)}>
                <Selector name={"Status"} value={statusFilter} labelStyle={"top"} onChange={setStatusFilter} data={[{name: "All", value: "all"}, {name: "Unassigned", value: "unassigned"},{name: "Bagged", value: "bagged"},{ name: "Picked Up", value: "pickedUp"}, {name: "Delivered", value: "delivered"}, {name: "Applied", value: "applied"}]} placeholder={"Site"}/>
              </div>
              <div className={helpers.clx(styles.filterContainer, styles.siteContainer)}>
                <Selector name={"Site"} value={siteFilter} labelStyle={"top"} onChange={setSiteFilter} data={[{name: "All", value: "all"}, {name: "Ahlstrom", value: "ARA"}, {name: "Jenkinson", value: "JNR"}, {name: "Test Site", value: "Test"}]}/>
              </div> 
              <div className={helpers.clx(styles.filterContainer, styles.dateContainer)}>
                <Selector name={"Date Type"} value={dateFilter} labelStyle={"top"} onChange={setDateFilter} data={[{name: "Production", value: "production"}, {name: "Assignment", value: "assigned"}, {name: "Scheduled", value: "scheduled"}, {name: "Completed", value: "completed"}, {name: "Pickup", value: "pickedUp"}, {name: "Application", value: "application"}]}/>
              </div> 
              <div className={helpers.clx(styles.filterContainer, styles.fromContainer)}>
                <DateSelector value={fromFilter} onChange={setFromFilter} name={"From"} labelStyle={"top"}/>
              </div>
              <div className={helpers.clx(styles.filterContainer, styles.toContainer)}>
                <DateSelector value={toFilter} onChange={setToFilter} name={"To"} labelStyle={"top"}/>
              </div>
              <div className={styles.filtersReset} onClick={resetFilters}>
                times-circle
              </div>
            </div>
            <table className={styles.bagsTable} >
              <tr className={styles.bagsHeaderRow}>
              <th className={styles.bagsHeader} onClick={() => handleSort('charcode')}>
                ID/Charcode {sortConfig.key === 'charcode' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
              </th>
              <th className={styles.bagsHeader} onClick={() => handleSort('status')}>
                Status {sortConfig.key === 'status' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
              </th>
              <th className={styles.bagsHeader} onClick={() => handleSort('farmer')}>
                Farmer {sortConfig.key === 'farmer' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
              </th>
              <th className={styles.bagsHeader} onClick={() => handleSort('site')}>
                Site {sortConfig.key === 'site' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
              </th>
              <th className={styles.bagsHeader} onClick={() => handleSort(dateFilter)}>
                {dateFilter === 'assigned' ? 'Assignment Date' : 
                dateFilter === 'production' ? 'Production Date' : 
                dateFilter === 'completed' ? 'Completed Date' : 
                dateFilter === 'scheduled' ? 'Scheduled Date' : 
                dateFilter === 'pickedUp' ? 'Pickup Date' : 
                dateFilter === 'application' ? "Application Date" : 
                "Logging Date"}
                { sortConfig.key.includes(dateFilter) ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
              </th>
              </tr>
                { 
                  renderBags()
                }     
            </table>
        </Module>
      </div>
    );
  }


  export default Bags;

  function formatDateToYYYYMMDD(date) {
    // Ensure the date is a Date object
    if (!(date instanceof Date)) {
        throw new Error("Invalid date");
    }

    // Extract the year, month, and day from the date
    const year = date.getFullYear();
    let month = date.getMonth() + 1; // Months are zero-based in JavaScript
    let day = date.getDate();

    // Add leading zeros to month and day if needed
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    // Return the formatted date string
    return `${year}-${month}-${day}`;
  }